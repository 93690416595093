import { Injectable } from '@angular/core';
import { Route, UrlSegment, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { NGXLogger } from 'ngx-logger';

/**
 * CodEasy Guard
 *
 * Prevents any un-authenticated access.
 */
@Injectable({
  providedIn: 'root',
})
export class CodEasyGuardService  {

  constructor(private router: Router,
              private logger: NGXLogger) {
  }

  canActivate(route: Route, segments: UrlSegment[]): boolean {
    if (!AuthService.isAuth()) {
      this.logger.info(`Unauthorized access, redirecting...`);
      this.router.navigate(['/auth', 'login']);
      return false;
    }
    return true;
  }
}
