import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthResponse } from '@interfaces/auth-response.interface';
import { ApiService } from '@services/api.service';
import { LocalizationService } from '@services/localization.service';
import { PusherService } from '@services/pusher.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodEasyService } from 'app/components/codeasy/codeasy.service';
import { ClientPlan } from '@enums/client-plan';

/**
 * Authentication Service
 *
 * User authentication status and details are handled here.
 * Sign in, sign up and sign out included with redirection.
 * To check if user is logged in, use `AuthService.isAuth()`.
 * To get the authentication token, use `AuthService.getToken()` and use `AuthService.setToken()` to update it.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  /**
   * Storage version to use to force user to sign in again (should only be increased)
   */
  static readonly STORAGE_VERSION = 2;

  /**
   * Storage key for storage version
   */
  static readonly STORAGE_VERSION_KEY = 'version';

  /**
   * Storage key for token
   */
  static readonly STORAGE_TOKEN_KEY = 'token';

  /**
   * Client Plan
   */
  static readonly STORAGE_CLIENT_PLAN = 'plan';

  /**
   * Storage key for refresh token
   */
  static readonly STORAGE_REFRESH_TOKEN_KEY = 'refresh-token';
  /**
   * Storage key for terms modal.
   */
  static readonly TERMS_KEY = 'terms';

  /**
   * Redirect to this route when signed out
   */
  static readonly SIGN_OUT_REDIRECT = '/auth/login';

  constructor(private http: HttpClient,
              private router: Router,
              private codEasyService: CodEasyService,
              private pusher: PusherService,
              private logger: NGXLogger) {
    /**
     * Check storage version
     * If user is signed in and version doesn't match, sign user out (force to sign in)
     */
    if (AuthService.isAuth()) {
      if (AuthService.STORAGE_VERSION !== Number(localStorage.getItem(AuthService.STORAGE_VERSION_KEY))) {
        this.logger.info('Client authentication version is old, signing out.');
        this.signOut();
      }
    }
  }

  /**
   * @return Whether user is authenticated or not
   */
  static isAuth(): boolean {
    return Boolean(localStorage.getItem(AuthService.STORAGE_TOKEN_KEY));
  }

  /**
   * Save and update authentication token
   *
   * @param token Authentication token
   * @param refresh Authentication refresh token
   */
  static setToken(token: string, refresh?: string): void {
    localStorage.setItem(AuthService.STORAGE_TOKEN_KEY, token);
    if (refresh) {
      localStorage.setItem(AuthService.STORAGE_REFRESH_TOKEN_KEY, refresh);
    }
  }

  /**
   * @returns Authentication token
   */
  static getToken(): string {
    return localStorage.getItem(AuthService.STORAGE_TOKEN_KEY);
  }

  /**
   * @returns Client Plan.
   */
  static getClientPlan(): ClientPlan {
    return localStorage.getItem(AuthService.STORAGE_CLIENT_PLAN) as ClientPlan || ClientPlan.BASIC;
  }

  /**
   * Un-authenticate user, reset everything and redirect
   */
  signOut(redirect: boolean = true): void {
    const signOut = () => {
      localStorage.clear();
      this.pusher.destroy();
      // Set terms accepted.
      localStorage.setItem(AuthService.TERMS_KEY, '1');
    };
    if (redirect) {
      this.router.navigateByUrl(AuthService.SIGN_OUT_REDIRECT).then(signOut);
    } else {
      signOut();
    }
  }

  /**
   * Call Auth API to refresh token.
   */
  refreshToken(): Observable<AuthResponse> {
    this.logger.info('Auth -> Refreshing token...');
    const refresh = localStorage.getItem(AuthService.STORAGE_REFRESH_TOKEN_KEY);
    return this.http.post<AuthResponse>(`${ApiService.v1}/users/token/refresh`, { refresh }).pipe(
      map((data: AuthResponse): AuthResponse => {
        this.logger.debug('Auth Token refreshed.', data);
        AuthService.setToken(data.access, data.refresh);
        return data;
      })
    );
  }

  /**
   * Sign user in
   *
   * Redirect to the right page based on how many companies user is a part of.
   *
   * @param email Email or ZipID
   * @param password User password
   * @param recaptcha ReCAPTCHA value
   */
  signIn(email: string, password: string, recaptcha?: string): Observable<AuthResponse> {
    const payload: { password: string; email: string, recaptcha?: string } = { email, password };
    if (recaptcha) {
      payload.recaptcha = recaptcha;
    }
    return this.http.post<AuthResponse>(`${ApiService.v1}/users/token`, payload).pipe(
      map((data: AuthResponse): AuthResponse => {
        /**
         * Store authentication token
         */
        AuthService.setToken(data.access, data.refresh);
        /**
         * Store client plan
         */
        localStorage.setItem(AuthService.STORAGE_CLIENT_PLAN, data.user.plan);
        /**
         * Store storage version
         */
        localStorage.setItem(AuthService.STORAGE_VERSION_KEY, AuthService.STORAGE_VERSION.toString());
        /**
         * Store language preference
         */
        LocalizationService.language = data.user.language;
        /**
         * Setup pusher token
         */
        this.pusher.setup(data.user.push_token);
        /**
         * Redirect user (determination done by the method)
         * @see CompanyService.redirect
         */
        if (data.user.change_password) {
          /**
           * Redirect user to change password view.
           */
          this.router.navigate(['/auth', 'password', 'change']);
        }
        this.codEasyService.goHome();
        return data;
      }),
    );
  }

  /**
   * Get Login URL.
   */
  static get loginURL(): string[] {
    return ['/auth', 'login'];
  }

  /**
   * Send forgot password request.
   */
  forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(`${ApiService.v1}/users/password/reset`, {email});
  }

  /**
   * Check password reset token.
   */
  checkPasswordToken(token: string): Observable<void> {
    return this.http.get<void>(`${ApiService.v1}/users/password/reset/token/${token}`);
  }

  /**
   * Change user password.
   * @param password The new password.
   * @param action_code Needed is the user is not logged in.
   */
  changePassword(password: string, action_code?: string): Observable<void> {
    const payload = {password, action_code};
    return this.http.put<void>(`${ApiService.v1}/users/password/reset/token`, payload);
  }

}
