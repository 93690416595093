import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodEasyGuardService } from '@app/components/codeasy/codeasy-guard.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@app/components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('@app/components/codeasy/codeasy.module').then(m => m.CodEasyModule),
    canActivate: [CodEasyGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
