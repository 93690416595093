import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

/**
 * App API service URLs based on current server.
 *
 * @example To get API of v2 call {@see ApiService.v2}
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {

  /**
   * @returns Base API URL for current server
   */
  static get base(): string {
    return 'https://gps-api.codeasy.co';
    if (environment.name === 'production') {
      return 'https://gps-api.codeasy.co';
    }
    return 'http://127.0.0.1:8000';
  }

  /**
   * @returns Base v1 API URL for current server
   */
  static get v1(): string {
    return `${ApiService.base}/v1`;
  }
}
