import { Environment } from '@interfaces/environment.interface';

export const environment: Environment = {
  name: 'production',
  pusherKey: '',
  reCaptchaKey: '6Lf16ugUAAAAAEqQIypOKl0vY6idJRx5s21O1go2',
  splashKey: 'Ku-_ZvhIT2boel4YRKEWlunQq44AHq_vUKZ6MahUs8U',
  splashAppName: 'codeasy-login',
  sentry: 'https://8252ffa583d04a82a776aebb5141a563@o404354.ingest.sentry.io/5267888',
};
