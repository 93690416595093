import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from '@services/auth.interceptor.service';
import { PusherService } from '@services/pusher.service';
import { ApiService } from '@services/api.service';
import { CommonModule } from '@angular/common';
import { CodEasyService } from '@app/components/codeasy/codeasy.service';
import { AppRoutingModule } from './app-routing.module';
import { SentryErrorHandler } from '@services/sentry.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { ActionService } from '@app/components/vehicles/status/action/action.service';
import { ToastrModule } from 'ngx-toastr';

/**
 * Check whether it's on production.
 * - workaround for "isDevMode()" to void the issue: Cannot enable prod mode after platform setup.
 */
const isProd = environment.name === 'production';
const LOG_LEVEL: NgxLoggerLevel = isProd ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG;

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: LOG_LEVEL,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: isProd}),
    ToastrModule.forRoot(),
  ],
  providers: [
    CodEasyService,
    ActionService,
    ApiService,
    PusherService,
    AuthInterceptor,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,
    'assets/i18n/', // custom path to translations files
    '.json',
  );
}
