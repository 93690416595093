import { Injectable, ErrorHandler } from '@angular/core';
import { CustomHttpErrorResponse } from '@app/shared/classes/custom-http-error-response';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor(private logger: NGXLogger) {
    if (environment.sentry) {
      Sentry.init({
        dsn: environment.sentry,
        environment: environment.name,
        enabled: location.href.includes('codeasy.co'),
        beforeSend(event: Sentry.Event, hint: Sentry.EventHint): Sentry.Event {
          // Handle any possible error type before sending...
          // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/filtering/#event-hints
          return event;
        },
      });
    }
  }

  /**
   * Log the error but only report it to sentry if it is not a prevented error.
   */
  handleError(error: any | CustomHttpErrorResponse): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    this.logger.info(error);
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    Sentry.captureException(error);
  }
}
