import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '@interfaces/api-response.interface';
import { VehicleAction } from '@interfaces/vehicle-action.interface';
import { ApiService } from 'app/shared/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ActionService {
  /**
   * Kept list of type names for cache.
   */
  private actions: ApiResponse<VehicleAction> = null;

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  /**
   * Send action command.
   */
  sendAction(actionID: string, vehicleID: string): Observable<void> {
    const params = { vehicle: vehicleID };
    return this.http.patch<void>(`${ApiService.v1}/vehicles/action/${actionID}`, params);
  }

  /**
   * Load vehicle actions.
   * - it take care of cache, requested just one time.
   */
  loadActions(): Observable<ApiResponse<VehicleAction>> {
    /**
     * Checking cache (this data doesn't change often).
     */
    if (this.actions) {
      this.logger.debug('Getting Vehicle-Actions list from cache.');
      return of(this.actions);
    }
    this.logger.debug('Getting Vehicle-Actions list from server.');

    return this.http.get<ApiResponse<VehicleAction>>(`${ApiService.v1}/vehicles/action`, {}).pipe(
      map((actions: ApiResponse<VehicleAction>) => {
        /**
         * Actions requested, add to cache to avoid further requests.
         */
        this.actions = actions;
        return actions;
    }));
  }
}
