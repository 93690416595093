import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthResponse } from '@interfaces/auth-response.interface';

/**
 * Authentication Interceptor
 *
 * Add the authentication headers when making an API call and user is authenticated.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private logger: NGXLogger) {
  }

  /**
   * Add authentication token to the Http Headers.
   * @param request Current request.
   * @param token Token string.
   */
  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * Override the headers if it's not a maps API endpoint (we don't want to send token to other APIs)
     */
    if (request.url.includes('codeasy.co') || request.url.includes('127.0.0.1')) {
      // If user authenticated, let's include the authentication token then.
      if (AuthService.isAuth()) {
        request = AuthInterceptor.addToken(request, AuthService.getToken());
      }
    }
    return next.handle(request).pipe(catchError((error: HttpErrorResponse): Observable<any> => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (request.url.includes('/token/refresh')) {
          // Refresh token is not valid anymore, so log out the user.
          this.logger.debug('Refresh token is not valid, log out the user.');
          this.authService.signOut();
          return throwError(error);
        }

        this.logger.debug('401 Unauthorized Error, trying to refresh token.');
        return this.authService.refreshToken().pipe(
          switchMap((data: AuthResponse) => {
            this.logger.debug('Retrying request with new token.', data);
            return next.handle(AuthInterceptor.addToken(request, data.access));
          })
        );
      } else {
        return throwError(error);
      }
    }));
  }
}
