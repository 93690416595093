import { Component, OnInit, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd, Data } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { LocalizationService } from '@services/localization.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CodEasyService } from 'app/components/codeasy/codeasy.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'app/environments/environment';
import { SwUpdate } from '@angular/service-worker';

declare var gtag: () => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Current route classes to apply to page wrapper
   */
  routeClass = '';
  /**
   * Update app flag.
   */
  isOutDated = false;

  private routerSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private title: Title,
              private codEasyService: CodEasyService,
              private renderer: Renderer2,
              private logger: NGXLogger,
              private swUpdate: SwUpdate,
              private translate: TranslateService) {
    /**
     * Backward compatibility for hash URLs
     */
    if (location.href.includes('/#')) {
      location.href = location.href.replace('/#', '');
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(newVersion => {
        /**
         * There is a new version of the app.
         */
        if (newVersion) {
          this.logger.info('There is a new version of the GPS-CodEasy App.');
          this.isOutDated = true;
        }
      });
    }
  }

  ngOnInit(): void {
    /**
     * Setup language preference
     */
    this.translate.setDefaultLang('es-es');
    this.translate.use(LocalizationService.language);
    /**
     * Redirect user if at index page based on authentication
     */
    if (location.pathname === '/') {
      if (AuthService.isAuth()) {
        this.codEasyService.goHome();
      } else { // redirect to login page if not logged in
        this.router.navigate(AuthService.loginURL);
      }
    }
    this.logger.info(`GPS-CodEasy App: Running app in [${environment.name}] mode.`);
    /**
     * Watch for page changes then update window title and class
     */
    this.router.events.pipe(
      filter((event): boolean => event instanceof NavigationEnd),
      map((): ActivatedRoute => this.route),
      map((activatedRoute: ActivatedRoute): ActivatedRoute => {
        while (activatedRoute.firstChild) {
          activatedRoute = activatedRoute.firstChild;
        }
        return activatedRoute;
      }),
      filter((activatedRoute: ActivatedRoute): boolean => activatedRoute.outlet === 'primary'),
      mergeMap((activatedRoute: ActivatedRoute): Observable<Data> => activatedRoute.data),
    ).subscribe((event: Data): void => {
      /**
       * Handle title
       */
      if (event.title) {
        this.translate.get(event.title).subscribe((text: string): void => {
          this.title.setTitle(`GPS-Easy - ${text}`);
        });
      }
      /**
       * Handle class
       */
      if (event.class) {
        this.routeClass = event.class;
      } else {
        this.routeClass = '';
      }
    });
  }

  ngAfterViewInit(): void {
    // subscribe to router events and send page views to Google Analytics
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // @ts-ignore
        gtag('config', 'G-9VQ95JNYZ7', {page_path: event.urlAfterRedirects});
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  /**
   * Do application update.
   */
  doUpdate(): void {
    this.logger.info('Updating application...');
    this.swUpdate.activateUpdate().then(() => {
      this.logger.info('Application updated.');
      this.isOutDated = false;
      document.location.reload();
    });
  }
}
