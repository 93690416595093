<div class="update-box" *ngIf="isOutDated">
  <h2 translate>THERE_IS_A_NEW_APP_VERSION</h2>
  <a href="#" (click)="doUpdate()" translate>UPDATE_NOW</a>
  <br />
  <a href="#" class="cancel" (click)="isOutDated=false">CANCEL</a>
</div>
<!-- Page -->
<div id="router-wrapper" [classList]="routeClass">
  <router-outlet></router-outlet>
</div>

<!-- Error handler -->
<!--<app-error-handler></app-error-handler>-->
