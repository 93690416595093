import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguagePreference } from '@enums/language-preference';
import { NameValue } from '@interfaces/name-value.interface';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

/**
 * This service will be used for all types of localization. Currently, we are just using this to
 * set the preferred language of the user. But going forward, this can be used for anything related to
 * customized experience for user based on geography.
 */
@Injectable()
export class LocalizationService {

  /**
   * Language key for localStorage
   */
  private static readonly STORAGE_KEY = 'language';

  /**
   * Supported languages
   */
  static readonly LANGUAGES: NameValue<LanguagePreference>[] = [{
    name: 'Español',
    value: LanguagePreference.ES,
  },
    {
      name: 'English',
      value: LanguagePreference.EN,
    },
  ];

  constructor(private http: HttpClient) {
  }

  /**
   * Change user language preference
   */
  static set language(preference: LanguagePreference) {
    localStorage.setItem(LocalizationService.STORAGE_KEY, LocalizationService.validate(preference));
  }

  /**
   * @returns Current user language preference
   */
  static get language(): LanguagePreference {
    return LocalizationService.validate(localStorage.getItem(LocalizationService.STORAGE_KEY) as LanguagePreference);
  }

  /**
   * @returns Validated language preference
   * @param preference Language preference to validate
   */
  static validate(preference: LanguagePreference) {
    if (LocalizationService.LANGUAGES.some(item => item.value === preference)) {
      return preference as LanguagePreference;
    }
    return LocalizationService.LANGUAGES[0].value;
  }

  /**
   * Change (or toggle) the language preference of the user
   * @param preference Language preference to set to (toggle if not set)
   */
  updateLanguagePreference(preference?: LanguagePreference): Observable<{ language: LanguagePreference }> {
    if (preference === LocalizationService.language) {
      return;
    }
    if (!preference) {
      preference = LanguagePreference.EN;
      if (LocalizationService.language === LanguagePreference.EN) {
        preference = LanguagePreference.ES;
      }
    }
    LocalizationService.language = preference;
    const url = `${ApiService.v1}/user/language`;
    return this.http.patch<{ language: LanguagePreference }>(url, { language: preference });
  }
}
