import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CodEasyService {
  constructor(private router: Router) { }

  /**
   * Redirect user to home Page.
   */
  goHome(): Promise<boolean> {
    return this.router.navigate(['vehicles', 'status']);
  }
}
