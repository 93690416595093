import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PusherMessageType } from '@enums/pusher-message-type';
import { PusherMessage } from '@interfaces/pusher-message.interface';
import { NGXLogger } from 'ngx-logger';
// import * as Pusher from 'pusher-js';

@Injectable()
export class PusherService {

  private static readonly STORAGE_KEY = 'push_token';
  private static readonly MAX_ERROR_LINES = 10;

  private pusher: any; // TODO implement Pusher (OneSignal)
  private channel: any;

  constructor(private logger: NGXLogger) {
    /**
     * Setup pusher if key is available
     */
    if (localStorage.getItem(PusherService.STORAGE_KEY)) {
      this.setup(localStorage.getItem(PusherService.STORAGE_KEY));
    }
  }

  /**
   * Setup pusher and subscribe to channel
   *
   * @param token Push token from back-end upon login
   */
  setup(token: string): void {
    // Validate token
    // if (!token || this.channel) {
    //   return;
    // }
    // // Store the token
    // localStorage.setItem(PusherService.STORAGE_KEY, token);
    // // Setup pusher
    // this.pusher = new Pusher(environment.pusherKey, { cluster: 'ap1' });
    // // Subscribe to pusher channel
    // this.channel = this.pusher.subscribe(token);
    // // Watch the notification event
    // this.bind('notification', (data: PusherMessage) => {
    //   this.logger.info('Received pusher.', data);
    //   this.toast(data);
    // });
    // // Watch successful event
    // this.bind('pusher:subscription_succeeded', () => {
    //   this.logger.info('Subscribed to pusher.');
    // });
    // // Watch unsuccessful event
    // this.bind('pusher:subscription_error', (status: number) => {
    //   this.logger.info('Failed to subscribed to pusher.', status);
    // });
  }

  /**
   * Unsubscribe from all pusher channels
   */
  destroy(): void {
    if (this.channel) {
      this.channel.unsubscribe('notification');
      this.channel.unsubscribe('pusher:subscription_succeeded');
      this.channel.unsubscribe('pusher:subscription_error');
      this.channel = null;
      this.logger.info('Unsubscribed from pusher.');
    }
  }

  /**
   * Bind to a pusher channel
   *
   * @param name Event name to bind
   * @param callback Function to invoke when even is triggered
   */
  bind(name: string, callback: (data: PusherMessage | number) => void): void {
    this.channel.bind(name, callback);
  }

  /**
   * Unsubscribe/unbind from a pusher channel
   *
   * @param name Event name to unsubscribe
   */
  unbind(name: string): void {
    this.channel.unbind(name);
  }

  /**
   * Parses the pusher notification data and toasts it
   * Turned into a separated method so other components can parse toast using it, like Payroll.
   *
   * @param data The message data received from pusher.
   */
  toast(data: PusherMessage): void {
    // Toast message
    // let content: string = data.content.message;
    // todo: show message
  }
}

